import React from 'react'
import {
  BackLink,
  Block,
  BlockImage,
  Content,
  HeadContent,
  HeadLine,
  ImageWrapper,
  PageName,
  Text,
  Wrapper,
} from '../styles/brandStoryPage'
import Image from '../components/image'
import CategoryTitle from '../components/Title/CategoryTitle'
import SubTitle from '../components/Title/SubTitle'
import SEO from '../components/seo'
import NextLink from '../components/NextLink'
import PageTransition from '../components/Animation/PageTransition'

const CONTENT_LIST = [
  {
    en: 'Stone',
    label: '石選び',
    image: 'photo/choice.jpg',
    text:
      '職人さんや専門家の意見、鑑定書を参考に自分の目で見て、触れて確かめた品質の良いものを厳選して使用しています。',
  },
  {
    en: 'Energies',
    label: 'エネルギー',
    image: 'photo/nature.jpg',
    text:
      '仕上がったアクセサリーは全て太陽、月、滝や森林など自然のエネルギーを取り入れています。',
  },
  {
    en: 'Design',
    label: 'デザイン',
    image: 'photo/design_2.jpg',
    text:
      '「ご縁・繋がり」というテーマで普段使いできる和モダンアクセサリーを提案しています。',
  },
]

const FeaturePage = () => {
  return (
    <>
      <SEO
        title="こだわり"
        keywords={[`高天原`, `アクセサリー`, `天然石`, `accessory`, `stone`]}
      />
      <Wrapper>
        <HeadLine>
          <BackLink to="/brandstory">←</BackLink>
          <PageName>こだわり</PageName>
          <div></div>
        </HeadLine>
        <ImageWrapper>
          <Image filename="photo/shiraito-taki.jpg" />
        </ImageWrapper>
        <Content>
          <HeadContent>
            <CategoryTitle en="Feature" label="こだわり" color="white" />
            <Text>
              アクセサリーを永く使っていただける様に品質にこだわっています。
              天然石のもつ本来の魅力を最大限に活かす為に「浄化」と「自然のエネルギー」を取り入れています。
            </Text>
          </HeadContent>

          {CONTENT_LIST.map(({ en, label, image, text }) => {
            return (
              <Block>
                <SubTitle en={en} label={label} />
                <BlockImage>
                  <Image filename={image} />
                </BlockImage>
                <Text>{text}</Text>
              </Block>
            )
          })}
          <NextLink label="身に付け方" path={'/howtowear'} />
        </Content>
        <PageTransition />
      </Wrapper>
    </>
  )
}

export default FeaturePage
